import { parseInt } from 'lodash';
import React, { useMemo } from 'react';
import {
    Card, Col, Container, Image, Row,
} from 'react-bootstrap';
import { openStripeCheckout, redirectToCheckout } from '../../utils/stripe';
import './style.scss';
import chev from '../../images/svg/chev-right.svg';
import { useSubscriptionAuth } from '../../context/subscriptionAuthContext';

const SubscribeCard = ({
    product,
    isLast,
    onHover,
    onLeave,
    fade,
}) => {
    const { products } = useSubscriptionAuth();

    const details = products[product] || {};

    const {
        currency,
        discount,
        label,
        sessionId,
        subtotal,
        title,
        total,
        trial,
    } = details;

    const isDiscounted = discount > 0;
    const labelText = useMemo(() => {
        if (label) return label;
        if (trial) return `${trial} day free trial`;
        if (isDiscounted) return `- ${parseInt((1 - (total / subtotal)?.toFixed(2)) * 100)}% off`;
        return null;
    }, [label, trial, total, subtotal]);

    console.log(label, trial, labelText);
    
    return (
        <Col
            onClick={() => openStripeCheckout(sessionId, product)}
            className={`subscribe-div__container mb-4 ${fade && 'subscribe-div__container--fade'}`}
            onMouseEnter={onHover}
            onMouseLeave={onLeave}
        >
            <Row className="d-flex justify-content-between">
                <Col xs={10}>
                    <div className="d-flex align-items-center">
                        <p className="subscribe-div__title">{title}</p>
                        {(label || isDiscounted || trial) && (
                            <p className="subscribe-card__label">
                                {labelText}
                            </p>
                        )}
                    </div>
                    <div className="subscribe-card__prices d-flex align-items-center">
                        <>
                            {(isDiscounted) && (
                                <div>
                                    <h3 className="subscribe-card__price">
                                        {currency?.placement === 'left' && currency?.symbol}
                                        {total}
                                        {currency?.placement !== 'left' && currency?.symbol}
                                    </h3>
                                </div>

                            )}

                            <h3 className={isDiscounted ? 'subscribe-card__sales-price' : 'subscribe-card__price'}>
                                {currency?.placement === 'left' && currency?.symbol}
                                {isDiscounted ? subtotal : total}
                                {currency?.placement !== 'left' && currency?.symbol}
                            </h3>
                        </>
                    </div>
                </Col>
                <Col xs={2} className="d-flex align-items-center">
                    <Image src={chev} style={{ width: '20px', height: '20px' }} />
                </Col>
            </Row>
            {!isLast && <hr />}
        </Col>
    );
};

export default SubscribeCard;
