import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Col, Form, Row } from 'react-bootstrap';
import BRAND_CONFIG from '../../BRAND_CONFIG';
import HeaderLogo from '../../components/header/HeaderLogo';
import Layout from '../../components/layout';
import Loading from '../../components/loading';
import SEO from '../../components/seo';
import SubscribeCard from '../../components/SubscribeCard';
import { useSubscriptionAuth } from '../../context/subscriptionAuthContext';
import { useLogout } from '../../utils/auth';
import { toArray } from 'lodash';
import useSubscriptionProducts from '../../hooks/useSubscriptionProducts';

const {
    name, logo, currencies, products: defaultProducts, saleProducts
} = BRAND_CONFIG;

const SubscriptionPage = () => {
    const store = useSubscriptionAuth();
    const { ready, currency, update, code, products } = store;
    const logout = useLogout();
    const [activeCard, setActiveCard] = useState(null);

    const { selectedProducts: productList, ready: sessionsReady } = useSubscriptionProducts(store);
    console.log('productList', productList, sessionsReady);

    return (
        <Layout>
            <SEO title="Subscribe" />
            <div className="page-wrapper">
                <Col />
                <Col className="mt-3 sub-form-container px-3 flex-sm-fill flex--grow-1">
                    {(ready && sessionsReady && !!productList?.length) ? (
                        <>
                            <HeaderLogo title={`Choose Your ${name} Subscription`} logo={logo} logoSize={100} />
                            {productList?.map(({
                                title, product, sessionId
                            }, index) => (
                                <SubscribeCard
                                    title={title}
                                    key={sessionId}
                                    product={product}
                                    fade={(activeCard !== null && activeCard !== index)}
                                    onHover={() => setActiveCard(index)}
                                    onLeave={() => setActiveCard(null)}
                                    isLast={products.length - 1 === index}
                                />
                            ))}
                            {currencies?.available?.length && (
                                <Row className="mt-5 d-flex align-items-sm-center justify-content-sm-center">
                                    <Form.Select
                                        aria-label="Currency Selector"
                                        onChange={({ target }) => {
                                            update('currency', target?.value);
                                            update('ready', false);
                                        }}
                                        className="form-control currency-selector"
                                        value={currency}
                                    >
                                        {
                                            currencies?.available?.map(
                                                (c) => <option value={c?.code} key={c?.code}>{`${c?.icon} ${c?.code}`}</option>,
                                            )
                                        }
                                    </Form.Select>
                                </Row>
                            )}
                        </>
                    ) : (
                        <Loading />
                    )}
                </Col>
                <Row className="info-row mt-5 d-flex align-items-sm-end">
                    <Col className="text-center" lg={12}>
                        <p className="body-font">
                            By signing up, you agree to our
                            {' '}
                            <a className="link-btn" target="_blank" href={BRAND_CONFIG.links.privacy} rel="noreferrer">
                                privacy policy
                            </a>
                            {' '}
                            and
                            {' '}
                            <a className="link-btn" target="_blank" href={BRAND_CONFIG.links.terms} rel="noreferrer">
                                terms and conditions
                            </a>
                        </p>
                        <p className="body-font">
                            Need help? Visit
                            {' '}
                            <a className="link-btn" target="_blank" href={BRAND_CONFIG.links.support} rel="noreferrer">
                                Support Center
                            </a>
                        </p>
                        <p className="body-font"><a className="link-btn" onClick={logout}>Log Out</a></p>
                        <p className="body-font">powered by GENFLOW</p>

                    </Col>
                </Row>

            </div>
        </Layout>
    );
};

export default SubscriptionPage;
